exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-tsx": () => import("./../../../src/pages/blog-en.tsx" /* webpackChunkName: "component---src-pages-blog-en-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-templates-activities-page-js": () => import("./../../../src/templates/activities-page.js" /* webpackChunkName: "component---src-templates-activities-page-js" */),
  "component---src-templates-admin-page-js": () => import("./../../../src/templates/adminPage.js" /* webpackChunkName: "component---src-templates-admin-page-js" */),
  "component---src-templates-documents-page-js": () => import("./../../../src/templates/documents-page.js" /* webpackChunkName: "component---src-templates-documents-page-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

